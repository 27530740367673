import React, { Component } from "react"

export class Sucess extends Component {
  render() {
    return (
      <div className="success">
        <h1>Thank you for your donation!</h1>
        <p>You should receive a confirmation email shortly.</p>
      </div>
    )
  }
}

export default Sucess
